﻿/**
 *  @ngdoc controller
 *  @name Fenestration Directive
 *  @description Fenestration Directive
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('fenestrationModel', function () {
        return {
            controller: 'fenestrationController',
            restrict: 'E',
            scope: {
                modelId: "@",
                lensData: '=',
                order: '=',
                patientname: '=',
                displayOnly: '='
            },
            templateUrl: 'app/directive/fenestration/fenestrationdir.html'
        }
    }).controller('fenestrationController', function ($scope) {

        $scope.fenestrationLocation = [
            { locationId: 1, value: 'Optic' },
            { locationId: 2, value: 'Haptic' },
            { locationId: 3, value: 'Both' }
        ]

        /**
        * @name openFenestrationModal
        * @description store predefinded object on open pop up trigger
        * @param {object} lens right or left eye object
        * @author Ajay
        */
        $scope.order.openFenestrationModal = (lens) => {
            let predefined;
            if (lens.orderLensFenestration?.isFenestration) {
                predefined = lens.orderLensFenestration;
            } else {
                predefined = {
                    isFenestration: false,
                    fenestrationCount: null,
                    locationId: null
                };
            }
            if (lens.isRightEye) {
                $scope.order.predefinedDenestrationRightModel = Object.assign({}, predefined);//predefined;
            } else {
                $scope.order.predefinedDenestrationLeftModel = Object.assign({}, predefined);;
            }
        }

        /**
        * @name saveFenestration
        * @description save only object on save
        * @param {object} lens right or left eye object
        * @author Ajay
        */
        $scope.order.saveFenestration = (lens) => {
            if (lens.orderLensFenestration) {
                $scope.order.fenestrationDummy = {};
                $scope.order.rightfenestrationDummy = {};
                $scope.order.leftfenestrationDummy = {};

                let fenestration = {
                    isFenestration: lens.orderLensFenestration.isFenestration,
                    fenestrationCount: lens.orderLensFenestration.fenestrationCount,
                    locationId: lens.orderLensFenestration.locationId
                };

                if (lens.isRightEye) {
                    angular.copy($scope.order.fenestrationDummy = fenestration, $scope.order.rightfenestrationDummy);
                } else {
                    angular.copy($scope.order.fenestrationDummy = fenestration, $scope.order.leftfenestrationDummy);
                }
            }
        }

        /**
        * @name loadPredefinedFenestration
        * @description re bind previous value on close or cancel click trigger.
        * @param {boolean} lens right or left eye object
        * @author Ajay
        */
        $scope.order.loadPredefinedFenestration = (lens) => {
            if ($scope.displayOnly !== true) {
                if (lens.isRightEye) {
                    if (!$scope.order.isEmpty($scope.order.rightfenestrationDummy)) {
                        lens.orderLensFenestration.isFenestration = $scope.order.rightfenestrationDummy.isFenestration;
                        lens.orderLensFenestration.fenestrationCount = $scope.order.rightfenestrationDummy.fenestrationCount;
                        lens.orderLensFenestration.locationId = $scope.order.rightfenestrationDummy.locationId;
                    } else {
                        if ($scope.order.predefinedDenestrationRightModel) {
                            lens.orderLensFenestration.isFenestration = $scope.order.predefinedDenestrationRightModel.isFenestration;
                            lens.orderLensFenestration.fenestrationCount = $scope.order.predefinedDenestrationRightModel.fenestrationCount;
                            lens.orderLensFenestration.locationId = $scope.order.predefinedDenestrationRightModel.locationId;
                        }
                    }
                }
                if (!lens.isRightEye) {
                    if (!$scope.order.isEmpty($scope.order.leftfenestrationDummy)) {
                        lens.orderLensFenestration.isFenestration = $scope.order.leftfenestrationDummy.isFenestration;
                        lens.orderLensFenestration.fenestrationCount = $scope.order.leftfenestrationDummy.fenestrationCount;
                        lens.orderLensFenestration.locationId = $scope.order.leftfenestrationDummy.locationId;
                    } else {
                        if ($scope.order.predefinedDenestrationLeftModel) {
                            lens.orderLensFenestration.isFenestration = $scope.order.predefinedDenestrationLeftModel.isFenestration;
                            lens.orderLensFenestration.fenestrationCount = $scope.order.predefinedDenestrationLeftModel.fenestrationCount;
                            lens.orderLensFenestration.locationId = $scope.order.predefinedDenestrationLeftModel.locationId;
                        }
                    }
                }
            }
        }

        /**
        * @name drillDotClear
        * @author Jaideep
        * @description claering drill dot data
        * @param {object} lens eye object
        * @returns {void}
        */
        $scope.order.fenestrationClear = function (lens) {
            lens.orderLensFenestration.isFenestration = false;
            lens.orderLensFenestration.fenestrationCount = null;
            lens.orderLensFenestration.locationId = null;
        }
    });

}(window.angular));